.container {
    overflow: auto;
    white-space: nowrap;
    padding: 30px;
    width: 60%;
    margin: 0 auto;
    /* transition: 2s; */
}

.topo_form {
    display: flex;
    flex-direction: row;
    background-color: #fff;
    padding: 20px;
    justify-content: space-between;
    border: solid 1px #174c4f;
    margin-bottom: 10px;
}
.mult_input, .form{
    column-gap: 10px;
    row-gap: 10px;
}

.div_container_topo,
.div_container_topo_add_contrato {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e5e7eb;
    padding: 10px;
    margin-top: 5px;
    flex-grow: 1;
    border: solid 1px #f3f3f3;
    border-radius: 3px;
}

.div_container_topo:hover,
.div_container_topo_add_contrato:hover {
    opacity: 0.9;
}

.div_container_topo_add_contrato {
    background-color: green;
    color: #fff;
}

.input_topo {
    width: 400px;
    background-color: #e5e7eb;
}

.filtro {
    width: 100%;
    background-color: #e5e7eb;
}

input:focus, select:focus,
.filtro:focus {
    outline: none;
}

table {
    border: solid 1px #174c4f;
    width: 100%;
}

.topo_tabela,
.corpo_tabela {
    background-color: #fff;
    flex-grow: 1;
}

.corpo_tabela {
    background-color: #f3f3f3;
    flex-grow: 1;
}

.titulo {
    flex-grow: 1;
    padding: 5px;
    text-align: left;
    border-style: solid;
    padding: 10px;
    color: #174c4f;
    text-transform: uppercase;
    transition: 2s;
}

.titulo p {
    margin: 0;
    text-transform: capitalize;
}

.titulo .nomeCliente {
    color: #2684c5;
}

.id {
    display: none;
}

.label_float {
    position: relative;
    padding-top: 10px;
    margin-top: 15px;
    display: flex;
    flex-grow: 1;
    min-width: 300px;
}

.label_float input, .label_float select {
    border: 1px solid lightgrey;
    outline: none;
    width: 100%;
    height: 50px;
    background-color: transparent;
    font-size: 16px;
    transition: all .3s ease-out;
    -webkit-transition: all .3s ease-out;
    -moz-transition: all .3s ease-out;
    -webkit-appearance: none;
    padding-left: 5px;
    border-radius: 5px;
    min-width: 300px;
}

.label_float input:focus {
    border: 1px solid #174c4f;
}

.label_float input::placeholder {
    color: transparent;
}

.label_float label {
    pointer-events: none;
    position: absolute;
    top: 10px;
    left: 5px;
    margin-top: 13px;
    transition: all .3s ease-out;
    -webkit-transition: all .3s ease-out;
    -moz-transition: all .3s ease-out;
    text-transform: capitalize;
}

.label_float input:focus+label,
.label_float select:focus+label,
.label_float input:not(:placeholder-shown)+label,
.label_float select:not(:placeholder-shown)+label {
    font-size: 13px;
    margin-top: -20px;
    color: #174c4f;
    left: 0px;
    text-transform: uppercase;
}

.form {
    flex-wrap: wrap;
    display: flex;
    padding: 10px;
}

.form button{
    flex-grow: 1;
    margin-top: 20px;
    width: 100%;
}

.botoes_topo {
    display: flex;
}
.botoes_topo button{
    flex-grow: 1;
    margin: 10px;
}
.container_dados{
    display: flex;
}

.modal_clientes {
    width: 50%;
    max-height: 95vh;
    overflow-x: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    border: 2px solid #000;
    box-shadow: 24;
}

.titulo {
    flex-grow: 1;
    padding: 5px;
    text-align: left;
    border-style: solid;
    padding: 10px;
    color: #174c4f;
    text-transform: uppercase;
    transition: 2s;
    cursor: pointer;
}

.titulo p {
    margin: 0;
    text-transform: capitalize;
}

.id {
    display: none;
}

@media screen and (min-width: 300px) and (max-width: 850px) {
    .topo_form {
        flex-direction: column;
        border-radius: 5px;
    }

    .botoes_topo {
        flex-direction: column;
    }

    .input_topo {
        width: 100%;
    }
    .modal_clientes {
        width: 95%;
    }

    .topo_tabela {
        display: none;
    }

    .titulo,
    table {
        border: none;
        text-align: center;
    }

    .corpo_tabela {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        padding: 10px;
        border-radius: 5px;
        border: solid 1px gray;
    }

    .titulo {
        margin: 0;
        padding: 0;
    }
    .opcoes {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
    }

    .id {
        display: flex;
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        color: #fff;
        background-color: #174c4f;
    }
}

@media screen and (min-width: 300px) and (max-width: 1024px) {
    .container {
        white-space: unset;
        padding: 10px;
        width: 100%;
    }

    .id {
        display: flex;
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        color: #fff;
        background-color: #174c4f;
    }

    .opcoes {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
    }

    .titulo,
    table {
        border: none;
        text-align: center;
    }

    .corpo_tabela {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        padding: 10px;
        border-radius: 5px;
        border: solid 1px gray;
    }

    .titulo {
        margin: 0;
        padding: 0;
    }

    .topo_tabela {
        display: none;
    }
}

@media screen and (min-width: 700px) and (max-width: 1024px) {
    .container {
        padding: 40px;
    }
    .modal_clientes {
        width: 70%;
    }

    .opcoes {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
    }

    .titulo,
    table {
        border: none;
        text-align: center;
    }

    .corpo_tabela {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        padding: 10px;
        border-radius: 5px;
        border: solid 1px gray;
    }

    .titulo {
        margin: 0;
        padding: 0;
    }

    .topo_tabela {
        display: none;
    }
}
@media screen and (min-width: 300px) and (max-width: 1300px) {
    .container_dados{
        flex-direction: column;
    }
}

@media screen and (min-width: 700px) and (max-width: 1920px) {
    .container {
        width: 80%;
    }
}