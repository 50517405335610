.container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
}

.form {
    width: 100%;
    padding: 25px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 0px;
}
.mult_input, .form{
    column-gap: 10px;
    row-gap: 10px;
}

.cabecalho {
    width: 100%;
    padding: 10;
    border-radius: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.titulo {
    text-align: left;
    color: #174c4f;
    font-weight: bold
}

.icone_cabecalho {
    font-size: 100px;
    color: #174c4f;
}

.link{
    cursor: pointer;
    color: #174c4f;
}
.link:hover{
    color: #174c4f;
    opacity: 0.8;
}

.icone {
    position: absolute;
    right: 5px;
    top: 25px;
    cursor: pointer;
    font-size: 25px;
    color: #174c4f;
}

.botao {
    width: 100%;
    background-color: green;
    height: 50px;
    border-radius: 0px;
    margin-top: 20px;
    color: white;
    border-radius: 5px;
}

.botao:hover {
    opacity: 0.9;
}
.mult_input{
    display: flex;
    flex-wrap: wrap;
}
.label_float {
    position: relative;
    padding-top: 10px;
    margin-top: 15px;
    display: flex;
    flex-grow: 1;
}

.label_float input {
    border: 1px solid lightgrey;
    outline: none;
    width: 100%;
    height: 50px;
    background-color: transparent;
    font-size: 16px;
    transition: all .3s ease-out;
    -webkit-transition: all .3s ease-out;
    -moz-transition: all .3s ease-out;
    -webkit-appearance: none;
    padding-left: 5px;
    border-radius: 5px;
    min-width: 300px;
}

.label_float input:focus {
    border: 1px solid #174c4f;
}

.label_float input::placeholder {
    color: transparent;
}

.label_float label {
    pointer-events: none;
    position: absolute;
    top: 10px;
    left: 5px;
    margin-top: 13px;
    transition: all .3s ease-out;
    -webkit-transition: all .3s ease-out;
    -moz-transition: all .3s ease-out;
    text-transform: capitalize;
}

.label_float input:focus+label,
.label_float input:not(:placeholder-shown)+label {
    font-size: 13px;
    margin-top: -20px;
    color: #174c4f;
    left: 0px;
    text-transform: uppercase;
}

@media(max-width: 600px) {
    .form {
        width: 100%;
    }
    .mult_input{
        display: flex;
        flex-direction: column;
    }
}

@media(min-width: 900px) {
    .form {
        width: 70%;
        background-color: #fff;
    }
}

@media(min-width: 1500px) {
    .form {
        width: 50%;
    }
}

@media(min-width: 1800px) {
    .form {
        width: 35%;
    }
}